/**
 * Master list of allowed colors
 */
export const availableColors = [
  {
    id: '#90DD75',
    title: 'Light Green',
    color: '#90DD75',
  },
  {
    id: '#28A745',
    title: 'Green',
    color: '#28A745',
  },
  {
    id: '#17A2B8',
    title: 'Light Blue',
    color: '#17A2B8',
  },
  {
    id: '#007BFF',
    title: 'Blue',
    color: '#007BFF',
  },
  {
    id: '#FFC107',
    title: 'Yellow',
    color: '#FFC107',
  },
  {
    id: '#FFA500',
    title: 'Dark Yellow',
    color: '#FFA500',
  },
  {
    id: '#FF9F45',
    title: 'Light Orange',
    color: '#FF9F45',
  },
  {
    id: '#FD7E14',
    title: 'Orange',
    color: '#FD7E14',
  },
  {
    id: '#D2B48C',
    title: 'Light Brown',
    color: '#D2B48C',
  },
  {
    id: '#6C757D',
    title: 'Gray',
    color: '#6C757D',
  },
  {
    id: '#343A40',
    title: 'Dark Gray',
    color: '#343A40',
  },
  {
    id: '#D6A2E8',
    title: 'Light Purple',
    color: '#D6A2E8',
  },
  {
    id: '#6F42C1',
    title: 'Purple',
    color: '#6F42C1',
  },
  {
    id: '#DC3545',
    title: 'Red',
    color: '#DC3545',
  },
  {
    id: '#E0554D',
    title: 'Dark Red',
    color: '#E0554D',
  },
];

/**
 * Convert data in to fomr options
 * @param items
 * @returns
 */
export function mapToOptions(items: any) {
  return items.map((item: any) => ({
    id: item.id,
    title: item.title,
  }));
}

export function mapToUsers(users: any, lang: string) {
  return users.map((user: any) => ({
    id: user.id,
    title: user[`first_name_${lang}`] + ' ' + user[`last_name_${lang}`],
  }));
}
